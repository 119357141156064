<template>
    <div
        @click.self="close"
        class="background_modal bl_modal">
        <div class="content bl_modal_content ly_modal_content">
            <slot></slot>
            <app-button
                name="閉じる"
                @click="close"
                ref="jest-modal-close"
                class="bl_modal_btnClose"
            />
        </div>
    </div>
</template>

<script>
/**
 * 汎用モーダル
 */
export default {
    name: 'AppModal',
    methods:{
        close(){
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.background_modal{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color:rgba(0, 0, 0, 0.1);
    top:0;
    left:0;
    z-index:1000;
    width:100vw;
    height:100vh;
}
.bl_modal_content{
    background-color: #FFF;
    font-size: 1rem;
    border-radius: 8px;
    box-shadow: 0 1px 1px 2px rgba(0,0,0, .1);
}
.ly_modal_content{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 35vw;
    max-width:800px;
    min-width:420px;
    height: auto;
    max-height: 60vh;
    padding:2rem;
}
.bl_modal_content>>>button{
    width: 150px;
    margin: 0 auto;
}
</style>
