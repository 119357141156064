<template>
    <default-template
        title="リンク一覧"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">リンク一覧</h2>
                <app-button
                    name="新規登録"
                    @click="createLinks"
                    ref="jest-organization-links-create"
                    class="inline"
                />
                <hr class="hp_flexspacer"/>
                <p v-show="showText">リンクがありません</p>
            </div>
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table v-show="showTable" class="lists_table">
                <tr>
                    <th>カテゴリー名</th>
                    <th>URL</th>
                    <th>登録日</th>
                    <th>承認日</th>
                    <th>種別</th>
                    <th class="lists_table_states">ステータス</th>
                    <th></th>
                    <th></th>
                </tr>
                <tr
                    v-for="item in links"
                    :key="'links' + item.id"
                    :ref="'jest-links' + item.id"
                >
                    <td :ref="'jest-links' + item.id + '_string_category_code'">{{item.string_category_code}}</td>
                    <td :ref="'jest-links' + item.id + '_url'">
                        <a
                            :href="item.url"
                            target="_blank"
                        >
                            {{item.url}}
                        </a>
                    </td>
                    <td :ref="'jest-links' + item.id + '_created_at'">{{stringDate(item.created_at)}}</td>
                    <td :ref="'jest-links' + item.id + '_approved_at'">{{stringDate(item.approved_at)}}</td>
                    <td>{{forEvaluatorString(item)}}</td>
                    <td>{{stringApproved(item.approved)}}</td>
                    <td :ref="'jest-links' + item.id + '_update'">
                        <app-button
                            name="変更"
                            @click="moveToEdit(item)"
                            :ref="'jest-organization-links-update' + item.id"
                            class="inline wd_80px"
                        />
                        <app-button
                            name="削除"
                            @click="moveToDelete(item)"
                            class="delete inline wd_80px"
                            :ref="'jest-organization-links-delete' + item.id"
                        />
                    </td> 
                </tr>
            </table>
            <list-pager
                v-show="showTable"
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * P130 リンク一覧
 *
 */
import ListPager from '@/components/molecules/ListPager'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { move, pageContentCount } from '@/util'
import Progress from '@/mixins/progress.js'

export default {
    name:'OrganizationsLinks',
    mixins:[Progress],
    components:{
        ListPager,
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await this.updateLinksForOrganization({organization_id:this.organization.id, page:1})
        this.finishProgress()
        loader.hide()
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
            organization:'auth/organization',
            links:'links/links',
            page:'links/page',
            total:'links/total',
        }),
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        isHouse(){
            return this.organization.type == 1
        },
        stringDate(){
            return function (date) {
                return date ? moment(date).format('YYYY年MM月DD日') : '-'
            }
        },
        showText() {
            return !this.isInProgress && this.links.length === 0
        },
        showTable() {
            return !this.isInProgress && this.links.length !== 0
        },
        stringApproved(){
            return function (approved) {
                return approved ? '承認済' : '非承認'
            }
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    methods:{
        ...mapActions({
            updateLinksForOrganization:'links/updateLinksForOrganization',
            updateLinks:'links/update',
        }),
        moveToEdit(item){
            this.updateLinks({
                category:'',
                level1:'',
                level2:'',
                level3:'',
                category_code:item.category_code,
                for_evaluator:item.for_evaluator,
                memo:item.memo,
                url:item.url,
                id:item.id,
            })
            move(this.$router, '/organization/links/' + item.id + '/edit')
        },
        async pageChange(page){
            const loader = this.$loading.show()
            this.startProgress()
            await this.updateLinksForOrganization({organization_id:this.organization.id, page})
            this.finishProgress()
            loader.hide()
        },
        moveToDelete(item){
            this.updateLinks({
                category:'',
                level1:'',
                level2:'',
                level3:'',
                category_code:item.category_code,
                for_evaluator:item.for_evaluator,
                memo:item.memo,
                url:item.url,
                id:item.id,
            })
            move(this.$router, '/organization/links/' + item.id + '/delete')
        },
        createLinks(){
            this.updateLinks({
                category:'',
                level1:'',
                level2:'',
                level3:'',
                category_code:'',
                for_evaluator:false,
                memo:'',
                url:'',
            })
            move(this.$router, '/organization/links/create')
        },
        forEvaluatorString(item) {
            return item.for_evaluator ? '評価機関専用' :'一般用'
        },

    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
}
.bl_underHeader{
    width: 100%;
}
table.lists_table{
    width: 100%;
}
.lists_table tr th{
    white-space: nowrap;
}
.lists_table tr td:first-child{
    max-width: 300px;
}
.el_button.inline.wd_80px{
    margin: 4px 2px;
}
</style>