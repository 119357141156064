<template>
    <default-template
        title="追加利用者登録完了"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_msgBox">
            <p>
                ご登録ありがとうございました。 <br>ご登録のメールアドレスに確認のメールが届きます。 <br>公開ページの反映は住宅性能評価・表示協会の確認後となります。<br>今しばらくお待ちくださるようお願いいたします。
            </p>
        </div>
        <div class="ly_buttonBox">
            <app-button
                name="完了"
                @click="moveTo('/organization/links')"
                ref="jest-link-create-finish"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * P122 事業者情報編集
 *
 */
import { mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUserCreateConfirm',
    components:{
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        moveTo(path){
            move(this.$router, path)
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
</style>