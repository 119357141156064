<template>
    <div class="form_box ly_form_box ly_userForm ly_linkForm">
        <div v-if="isNew">
            <div class="formrow">
                <app-label
                    name="category_code"
                    label="登録カテゴリー"
                    class="rowlabel grid1"
                />
                <app-button
                    v-if="!isCategoryList"
                    name="登録カテゴリー検索"
                    @click="toggleSeachBoxModal"
                    class="inline wd_200px"
                />
                <div>
                    <app-select
                        v-if="isCategoryList"
                        v-model="category_code"
                        :label="'選択してください'"
                        :vmodel-label="categoryString"
                        ref="jest-link-category-code"
                    >
                        <app-select-option 
                            :value="''"
                            v-model="category_code"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="item in categories"
                            :key="'categories' + item.code"
                            :value="item.code"
                            v-model="category_code"
                        >
                            {{label(item)}}
                        </app-select-option>
                    </app-select>
                    <app-button
                        v-if="isCategoryList"
                        name="再検索"
                        @click="toggleSeachBoxModal"
                        class="inline"
                    />
                </div>
            </div>
        </div>
        <div v-else class="formrow">
            <app-label
                name="登録カテゴリー"
                label="登録カテゴリー"
                class="rowlabel grid1"
            />
            <p>{{categoryString}}</p>
        </div>
        <app-text
            name="url"
            v-model="url"
            text-box-id="url"
            :lbl_required="true"
            label="URL"
            class="formrow url"
            ref="jest-organization-link-url"
        />
        <div v-if="isNew">
            <div class="formrow">
                <app-label
                    name="リンクの種類"
                    label="リンクの種類"
                    class="rowlabel grid1"
                />
                <div>
                    <app-radio
                        :value="false"
                        label="一般用"
                        name="for_evaluator"
                        v-model="for_evaluator"
                    />
                    <app-radio
                        :value="true"
                        label="評価機関専用"
                        name="for_evaluator"
                        v-model="for_evaluator"
                    />
                </div>
            </div>
        </div>
        <div v-else class="formrow">
            <app-label
                name="for_evaluator"
                label="リンクの種類"
                class="rowlabel grid1"
            />
            <p>{{forEvaluatorString}}</p>
        </div>
        <app-text
            v-if="for_evaluator"
            name="memo"
            v-model="memo"
            label="リンクテキスト"
            class="formrow"
            ref="jest-organization-link-memo"
        />
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
import AppButton from '../atoms/AppButton.vue'

export default {
  components: { AppButton },
    name:'LinkForm',
    props:{
        isNew:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        categories:{
            handler: function(){
                if(this.isNew) {
                    this.category_code = ''
                }
            },
            deep:true,
        }
    },
    computed:{
        ...mapGetters({
            data:'links/dataForCreateLinks',
            categories:'links/categories',
        }),
        ...mapComputedProperties([
            'category_code',
            'url',
            'memo',
            'for_evaluator',
        ]),
        label() {
            return function (item) {
                return item.level1 + ' > ' + item.level2 + ' > ' + (item.level3 ? item.level3 + ' > ' : '') + item.category
            }
        },
        categoryString() {
            const item = this.categories.filter((item) => item.code == this.category_code)[0]
            return item ? item.level1 + ' > ' + item.level2 + ' > ' + (item.level3 ? item.level3 + ' > ' : '') + item.category : null
        },
        forEvaluatorString() {
            return this.for_evaluator ? '評価機関専用' :'一般用'
        },
        isCategoryList(){
            return this.categories.length != 0 && this.categories.length <= 40
        }
    },
    methods:{
        ...mapActions({
            update:'links/update'
        }),
        toggleSeachBoxModal(){
            this.$emit('toggle-seach-box-modal')
        }
    }
}
</script>

<style scoped>
.form_box .formrow{
    grid-template-columns: 8em 1fr;
    -ms-grid-columns: 8em 1fr;
}
.ly_linkForm{
    width: 100%;
}
</style>