<template>
    <default-template
        title="新規リンク登録"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2
                    v-if="isNew"
                    class="el_lv2Heading"
                >
                    リンク登録
                </h2>
                <h2
                    v-if="!isNew"
                    class="el_lv2Heading"
                >
                    リンク変更
                </h2>
            </div>
            <error-list />
            <app-modal
                v-if="isShowSeachBoxModal"
                @close="toggleSeachBoxModal"
                class="uq_searchBoxModal"
            >
                <div v-if="isNew">
                    <div class="bl_searchBox_outer">
                        <p class="bl_searchBox_outer_msg">登録カテゴリーの検索条件を入力して検索ボタンをクリックしてください。</p>
                        <hr class="hp_flexspacer"/>
                        <div class="bl_searchBox ly_searchBox">
                            
                            <div class="uq_flexcolumn">
                                <div class="bl_input_container">
                                    <app-label
                                        name="searchWithCategory"
                                        label="検索方法"
                                    />
                                    <div>
                                        <app-radio
                                            v-model="searchWithCategory"
                                            :value="false"
                                            label="分類を選択して検索"
                                        />
                                        <app-radio
                                            v-model="searchWithCategory"
                                            :value="true"
                                            label="カテゴリー名を入力して検索"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="bl_input_container"
                                    v-if="!searchWithCategory"
                                >
                                    <app-label
                                        name="category1"
                                        label="⼤分類"
                                    />
                                    <div>
                                        <app-radio
                                            v-if="isHouse"
                                            v-model="level1"
                                            value="躯体の外皮性能等"
                                            label="躯体の外皮性能等"
                                            ref="jest-links-level1-house"
                                        />
                                        <app-radio
                                            v-if="!isHouse"
                                            v-model="level1"
                                            value="躯体の断熱性能"
                                            label="躯体の断熱性能"
                                            ref="jest-links-level1-building"
                                        />
                                        <app-radio
                                            v-model="level1"
                                            value="一次エネルギー消費量"
                                            label="一次エネルギー消費量"
                                        />
                                        <app-radio
                                            v-if="isHouse"
                                            v-model="level1"
                                            value="その他基準"
                                            label="その他基準"
                                        />
                                    </div>
                                </div>
                                
                                <div
                                    class="bl_input_container"
                                    v-if="!searchWithCategory"
                                >

                                    <app-label
                                        name="category1"
                                        label="中分類"
                                    />
                                    <app-select
                                        v-model="level2"
                                        :label="'選択してください'"
                                        :vmodel-label="level2"
                                        ref="jest-public-links-level2"
                                    >
                                        <app-select-option
                                            :value="''"
                                            v-model="level2"
                                        >
                                            選択してください
                                        </app-select-option>
                                        <app-select-option
                                            v-for="(item, index) in categoriesLevelTwo"
                                            :key="index"
                                            :value="item"
                                            v-model="level2"
                                        >
                                            {{item}}
                                        </app-select-option>
                                    </app-select>
                                </div>
                                <div
                                    class="bl_input_container"
                                    v-if="!searchWithCategory"
                                >
                                    <app-label
                                        name="category1"
                                        label="小分類"
                                    />
                                    <app-select
                                        v-model="level3"
                                        :label="'選択してください'"
                                        :vmodel-label="level3"
                                        ref="jest-public-links-level2"
                                    >
                                        <app-select-option
                                            :value="''"
                                            v-model="level3"
                                        >
                                            選択してください
                                        </app-select-option>
                                        <app-select-option
                                            v-for="(item, index) in categoriesLevelThree"
                                            :key="index"
                                            :value="item"
                                            v-model="level3"
                                        >
                                            {{level3Item(item)}}
                                        </app-select-option>
                                    </app-select>
                                </div>
                                <div
                                    class="bl_input_container"
                                    v-if="searchWithCategory"
                                >
                                    <app-text
                                        name="category"
                                        v-model="category"
                                        text-box-id="category"
                                        label="カテゴリー名"
                                        class="formrow uq_formrow__flexrow"
                                        ref="jest-organization-link-category"
                                    />
                                </div>
                            </div>
                            
                        </div>
                        <div class="uq_modal_buttonBox">
                            <app-button
                                name="閉じる"
                                @click.self="hideSeachBoxModal"
                                class=" cancel"
                            />
                            <app-button
                                name="検索"
                                @click="updateList"
                                ref="jest-public-links-select"
                                class=""
                            />
                        </div>
                    </div>
                </div>
            </app-modal>
            
            <link-form
                :is-new="isNew"
                @toggle-seach-box-modal="toggleSeachBoxModal"
            />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="cancel"
                    ref="jest-public-links-create-cancel"
                    class="cancel"
                />
                <app-button
                    name="入力内容確認"
                    @click="confirm"
                    ref="jest-public-links-create-confirm"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P131 リンク登録画面
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import LinkForm from '@/components/organisms/LinkForm'
import AppModal from '@/components/molecules/AppModal'
import { mapActions, mapGetters } from 'vuex'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapComputedProperties, move } from '@/util'

export default {
    name:'OrganizationLinksCreate',
    components:{
        AppModal,
        ErrorList,
        LinkForm,
    },
    mounted(){
        scroll(0, 0)
        this.updateList()
    },
    watch:{
        level1(){
            this.level2 = ''
            this.level3 = ''
        },
        level2(){
            this.level3 = ''
        }
    },
    data(){
        return{
            isShowSeachBoxModal: false,
        }
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
            data:'links/categoryForCreateLinks',
            categoriesLevelTwo:'links/categoriesLevelTwo',
            categoriesLevelThree:'links/categoriesLevelThree',
            categories:'links/categories',
            id:'links/id',
            validation:'links/validation',
            validationError:'links/validationError'
        }),
        ...mapComputedProperties([
            'level1',
            'level2',
            'level3',
            'category',
            'searchWithCategory',
        ]),
        isNew(){
            return this.$route.path.includes('create')
        },
        isHouse(){
            return this.organization.type == 1
        },
        typeString(){
            return this.isHouse ? 'house' : 'building'
        },
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            update:'links/update',
            updateCategories:'links/updateCategories',
            updateCategoriesLevels: 'links/updateCategoriesLevels',
        }),
        updateList:async function(){
            const loader = this.$loading.show()
            if (this.isNew) {
                this.update({category_code:''})
            }
            await Promise.all([
                this.updateCategories(this.typeString),
                this.updateCategoriesLevels(this.typeString),
            ])
            if (this.isNew && this.categories.length === 1) {
                this.update({category_code:this.categories[0].code})
            }
            loader.hide()
            this.hideSeachBoxModal()
        },
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        cancel(){
            this.moveTo('/organization/links')
        },
        confirm(){
            this.clearError()
            if (this.validation) {
                if (this.isNew) {
                    move(this.$router, '/organization/links/create/confirm')
                } else {
                    move(this.$router, '/organization/links/' + this.id + '/edit/confirm')
                }
            } else {
                this.updateError(this.validationError)
            }
        },
        toggleSeachBoxModal(){
            this.isShowSeachBoxModal = !this.isShowSeachBoxModal
            if (this.isShowSeachBoxModal) {
                // 下層レイヤのスクロール位置を固定する
                disableBodyScroll(document.querySelector('#app'))
            } else {
                // 下層レイヤのスクロール位置固定を解除
                clearAllBodyScrollLocks()
            }
        },
        hideSeachBoxModal(){
            this.isShowSeachBoxModal = false
            if (this.isShowSeachBoxModal) {
                // 下層レイヤのスクロール位置を固定する
                disableBodyScroll(document.querySelector('#app'))
            } else {
                // 下層レイヤのスクロール位置固定を解除
                clearAllBodyScrollLocks()
            }
        },
        level3Item(item){
            return item ?? '-';
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 820px;
    max-width: 820px;
}
.bl_contents_wrapper >>> .uq_searchBoxModal .ly_modal_content{
    min-width: fit-content;
    min-height: 320px;
}
.ly_linkForm {
    max-width: calc(100% - 80px);
}
.bl_searchBox_outer{
    width: 800px;
}
.bl_contents_wrapper >>> .uq_searchBoxModal .ly_searchBox{
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 16px;
    min-height: 190px;
}
.bl_searchBox_outer_msg{
    margin-bottom: 4px;
}
.form_box >>> div.formrow{
    border-bottom: none;
}
.bl_input_container:last-of-type{
    margin-top: 4px;
}
.bl_input_container .el_label{
    width: 6em;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.uq_flexcolumn:first-child{
    margin-right: 22px;
}
.uq_flexcolumn.uq_wd{
    width: fit-content;
    justify-content: space-between;
    padding-left: 16px;
}
.el_label.uq_wd_3em{
    width: 3em;
    margin-left: 32px;
}
.uq_formrow__flexrow{
    display: flex;
    flex-direction: row;
}
.uq_formrow__flexrow >>> .el_label{
    margin-right: 16px;
}
.uq_flexcolumn >>> .el_button.uq_leftMargin{
    margin-left: auto;
}
.uq_modal_buttonBox{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 100%;
}
.uq_modal_buttonBox .el_button:first-of-type{
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    left: -2px;
}
.bl_contents_wrapper >>> .bl_modal_btnClose{
    display: none;
}
.bl_contents_wrapper >>> .select_wrapper div.select_btn{
    white-space: normal;
    min-width: 15em;
}
</style>