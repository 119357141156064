<template>
    <default-template
        :title="'リンク' + submitName + '確認'"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">リンク{{submitName}}確認</h2>
            </div>
            <error-list />
            <p v-if="isNew">登録内容を確認してください。</p>
            <p v-else-if="isDelete">下記のリンクを削除します。よろしいですか？</p>
            <p v-else>編集内容を確認してください。</p>
            <link-confirm />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="cancel"
                    ref="jest-link-create-cancel"
                    class="cancel"
                />
                <app-button
                    :name="submitName"
                    @click="submit"
                    :class="{delete:isDelete}"
                    ref="jest-link-create-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P131 リンク登録画面
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import LinkConfirm from '@/components/organisms/LinkConfirm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationLinksCreate',
    components:{
        ErrorList,
        LinkConfirm,
    },
    mounted(){
        scroll(0, 0)
        this.updateCategories(this.typeString)
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
            id:'links/id',
        }),
        isHouse(){
            return this.organization.type == 1
        },
        typeString(){
            return this.isHouse ? 'house' : 'building'
        },
        isNew(){
            return this.$route.path.includes('create')
        },
        isDelete(){
            return this.$route.path.includes('delete')
        },
        submitName(){
            if (this.isNew) {
                return '登録'
            } else if (this.isDelete) {
                return '削除'
            } else {
                return '更新'
            }
        }
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            updateCategories:'links/updateCategories',
            createLink:'links/createLink',
            editLink:'links/editLink',
            deleteLink:'links/deleteLink',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        cancel(){
            if (this.isNew) {
                this.moveTo('/organization/links/create')
            } else if (this.isDelete) {
                this.moveTo('/organization/links')
            } else {
                this.moveTo('/organization/links/' + this.id + '/edit')
            }
        },
        submit(){
            this.clearError()
            if (this.isNew) {
                this.create()
            } else if (this.isDelete) {
                this.delete()
            } else {
                this.edit()
            }
        },
        edit(){
            this.editLink({id:this.id, type:this.organization.type})
                .then(() => {
                    move(this.$router, '/organization/links')
                }, reject => {
                    this.updateError(reject.response)
                    move(this.$router, '/organization/links/' + this.id + '/edit')
                })
        },
        create(){
            this.createLink(this.organization.type)
                .then(() => {
                    move(this.$router, '/organization/links/create/complete')
                }, reject => {
                    this.updateError(reject.response)
                    move(this.$router, '/organization/links/create')
                })
        },
        delete(){
            this.deleteLink(this.id)
                .then(() => {
                    move(this.$router, '/organization/links')
                }, reject => {
                    this.updateError(reject.response)
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
</style>