<template>
    <div class="form_box ly_form_box ly_userForm">
        <div class="formrow">
            <app-label
                name="登録カテゴリー"
                label="登録カテゴリー"
                class="rowlabel grid1"
            />
            <p>{{categoryString}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="URL"
                label="URL"
                class="rowlabel grid1"
            />
            <p>{{url}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="for_evaluator"
                label="リンクの種類"
                class="rowlabel grid1"
            />
            <p>{{forEvaluatorString}}</p>
        </div>
        <div v-if="for_evaluator" class="formrow">
            <app-label
                name="memo"
                label="リンクテキスト"
                class="rowlabel grid1"
                ref="jest-links-memo"
            />
            <p>{{memo}}</p>
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'LinkConfirm',
    props:{
        isNew:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapGetters({
            data:'links/dataForCreateLinks',
            categories:'links/categories',
        }),
        ...mapComputedProperties([
            'category_code',
            'url',
            'memo',
            'for_evaluator',
        ]),
        categoryString() {
            const item = this.categories.filter((item) => item.code == this.category_code)[0]
            return item ? item.level1 + ' > ' + item.level2 + ' > ' + (item.level3 ? item.level3 + ' > ' : '') + item.category : ''
        },
        forEvaluatorString() {
            return this.for_evaluator ? '評価機関専用' :'一般用'
        }
    },
    methods:{
        ...mapActions({
            update:'links/update'
        })
    }
}
</script>

<style scoped>
.ly_form_box.ly_userForm{
    display: flex;
    flex-direction: column;
    min-width: 500px;
}
.ly_userForm .formrow{
    grid-template-columns: 8em 1fr;
    -ms-grid-columns: 8em 1fr;
}
.formrow>p:last-child{
    display: inline-block;
    max-width: 40em;
    white-space: pre-wrap;
    word-wrap: break-word;
}
</style>